import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const MeterRegistration = lazy(() =>
  import("../../container/meter/meterRegistration")
);
const ClientMeterLink = lazy(() =>
  import("../../container/meter/meterClientLink")
);
const MeterList = lazy(() => import("../../container/meter/meterList"));
const MeterEdit = lazy(() => import("../../container/meter/meterEdit"));
const MeterDetails = lazy(() =>
  import("../../container/meter/MeterDetails/meterDetails")
);
const PaymentDetails = lazy(() =>
  import("../../container/meter/PaymentDetails/paymentDetails")
);
const PaymentInvoice = lazy(() =>
  import("../../container/meter/PaymentInvoice/paymentInvoice")
);
const PayoutsPdf = lazy(() =>
  import("../../container/meter/PaymentDetails/payoutsPdf")
);

function MeterRoute() {
  return (
    <Routes>
      <Route path="list" element={<MeterList />} />
      <Route path="registration" element={<MeterRegistration />} />
      <Route path="client-meter-link" element={<ClientMeterLink />} />
      <Route path="meter-edit/:meterNumber" element={<MeterEdit />} />
      <Route path="meter-details" element={<MeterDetails />} />
      <Route path="payment-details" element={<PaymentDetails />} />
      <Route path="payment-invoice/:meterNumber" element={<PaymentInvoice />} />
      <Route path="payouts-pdf" element={<PayoutsPdf />} />
    </Routes>
  );
}

export default MeterRoute;
