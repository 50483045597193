import { Spin } from "antd";
import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Pages from "./pages";
import Client from "./client";
import Meter from "./meter";
import withAdminLayout from "../../layout/withAdminLayout";

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="client/*" element={<Client />} />
        <Route path="meter/*" element={<Meter />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
