import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const ClientRegistration = lazy(() =>
  import("../../container/client/clientRegistration")
);
const ClientList = lazy(() => import("../../container/client/clientList"));
const ClientPaymentInfo = lazy(() =>
  import("../../container/client/clientPaymentInfo")
);
const ClientDetails = lazy(() =>
  import("../../container/client/ClientDetail/clientDetails")
);
const PaymentInvoice = lazy(() =>
  import("../../container/client/PaymentInvoice/paymentInvoice")
);
function ClientRoute() {
  return (
    <Routes>
      <Route path="registration" element={<ClientRegistration />} />
      <Route path="list" element={<ClientList />} />
      <Route path="client-payment-info" element={<ClientPaymentInfo />} />
      <Route path="client-details" element={<ClientDetails />} />
      <Route
        path="payment-invoice/:registrationId"
        element={<PaymentInvoice />}
      />
    </Routes>
  );
}

export default ClientRoute;
